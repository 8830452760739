import { get } from '~/services/http-requests'

const _BS_REFERAL = 'activated_referrals'

/*
 **** GET ****
 */
function fetchActivatedReferrals(): Promise<IReferral[]> {
    return get({ baseProperty: _BS_REFERAL })
}

export { fetchActivatedReferrals }
