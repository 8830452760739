









































import Vue from 'vue'
import { Prop, Component, Mixins } from 'vue-property-decorator'

// @ts-ignore
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import VLoadSpinner from '~/components/VLoadSpinner.vue'
import { Dropdowns } from '~/mixins/dropdowns'

import { UserStore } from '@/store/user'
import { ModalStore, ModalType, IModalParams } from '~/store/modal'
import { fetchActivatedReferrals } from '#/api/referral'

@Component({ components: { VLoadSpinner } })
export default class PromotionPage extends Mixins(Dropdowns) {
    activatedReferrals: IReferral[] = []

    @UserStore.State('authenticatedUser') authenticatedUser: any

    get referrLink() {
        return `https://thecharts.com/?ref=${this.authenticatedUser ? this.authenticatedUser.referral_code : ''}`
    }

    @ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (modalParams: IModalParams) => void

    async _fetchActivatedReferrals() {
        this.activatedReferrals = await fetchActivatedReferrals()
    }

    created() {
        this._fetchActivatedReferrals()
    }
}
